<template>
  <div class="reply-comment">
    <edit-comments
      class="edit-story-reply-comments"
      v-if="!visibleReplyComment"
      :profilePicture="profilePicture"
      :comment="comment"
      @close-comment-box="cancelCommentReply"
      :commentId="replyId"
      :storyId="storyId"
      :parentCommentId="parentCommentId" 
      :commentFor="commentFor"
      :interestGroupId="interestGroupId"
      :isPrivateTopic="isPrivateTopic"
    ></edit-comments>
    <div v-if="visibleReplyComment">
      <div class="reply-comment__comment-details">
        <div class="user-image">
          <img :src="profilePicture" />
        </div>
        <div class="user-details">
          <div class="user-details__header">
            <h4>
              {{ fullName }}<span>{{ time }}</span>
            </h4>
            <a-dropdown
              :trigger="['click']"
              class="dropdown"
              v-if="isLoggedInUser == userId"
            >
              <a class="ant-dropdown-link" @click.prevent>
                <i class="icon icon-vertical_three-dots"></i>
              </a>
              <template #overlay>
                <a-menu class="comment-dropdown">
                  <a-menu-item key="0">
                    <a @click="editCommentReply()">Edit</a>
                  </a-menu-item>
                  <a-menu-item key="1">
                    <a @click="visibleCommentDeleteModal()">Delete</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
          <div class="user-details__body">
            <p v-if="comment.includes('youtube.com') || comment.includes('vimeo.com')">
              <a :href="comment" target="_black">{{ comment }}</a>
            </p>
            <p v-else>
              {{ comment }}
            </p>
            <div v-if="file" class="pdf-file-container">
              <PdfCard
                :pdfFileName="fileName"
                :pdfUrl="file"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="reply-comment__comment-btns">
        <!-- <button>Like</button> -->
      </div>
    </div>
  </div>
  <delete-modal
    v-if="commentFor == 'impact'"
    :visible="showCommentDeleteModal"
    @handle-close="showCommentDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteImpactCommentReply(replyId)"
  ></delete-modal>
  <delete-modal
   v-if="commentFor == 'discover'"
    :visible="showCommentDeleteModal"
    @handle-close="showCommentDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteDiscoverCommentReply(replyId)"
  ></delete-modal>
  <delete-modal
   v-if="commentFor == 'topics'"
    :visible="showCommentDeleteModal"
    @handle-close="showCommentDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteDiscussionCommentReply(replyId)"
  ></delete-modal>
  <delete-modal
    v-if="commentFor == 'saved-folder'"
    :visible="showCommentDeleteModal"
    @handle-close="showCommentDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteSavedFolderCommentReply(replyId)"
  ></delete-modal>
  <delete-modal
    v-if="commentFor == 'folders'"
    :visible="showCommentDeleteModal"
    @handle-close="showCommentDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteFolderCommentReply(replyId)"
  ></delete-modal>
</template>

<script>
import EditComments from "./EditComment.vue";
import DeleteModal from "../DeleteModal.vue";
import PdfCard from "../../GlocalFolders/CommentPDFCard.vue";

export default {
  components: {
    EditComments,
    DeleteModal,
    PdfCard
  },
  props: {
    profilePicture: String,
    fullName: String,
    commentsNestedReplies: Array,
    comment: String,
    file: String,
    fileName: String,
    storyId: String,
    replyId: String,
    parentCommentId: String,
    displayPicture: String,
    time: String,
    userId: Number,
    commentFor: String,
    interestGroupId: String,
    isPrivateTopic: Boolean
  },
  computed: {
    isLoggedInUser() {
      return this.$store.getters["profile/currentLoginUser"];
    },
    // interestGroupId(){
    //   return this.$store.getters["article/getInterestGroupId"];
    // }
    getAllFolderDiscussions() {
      return this.$store.getters["folders/getFolderComments"].results;
    },
  },
  data() {
    return {
      visibleReplyComment: true,
      showCommentDeleteModal: false,
      loader: false,
    };
  },
  methods: {
    editCommentReply() {
      this.visibleReplyComment = false;
    },
    cancelCommentReply() {
      this.visibleReplyComment = true;
    },
    visibleCommentDeleteModal() {
      this.showCommentDeleteModal = true;
    },
    async deleteImpactCommentReply(replyCommentId) {
      this.loader = true;
      try {
        const payload = {
          comment_id: replyCommentId,
          story_id: this.storyId,
        };
        const response = await this.$store.dispatch(
          "impact/deleteImpactArticleComment",
          payload
        );
        if (response.status === 204) {
          await this.$store.dispatch(
            "impact/fetchImpactArticleComments",
            this.storyId
          );
          this.loader = false;
          this.showCommentDeleteModal = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async deleteDiscoverCommentReply(replyCommentId){
      this.loader = true;
      try {
        const payload = {
          comment_id: replyCommentId,
          article_id: this.storyId,
        };
        const response = await this.$store.dispatch(
          "discover/deleteDiscoverArticleComment",
          payload
        );
        if (response.status === 204) {
          await this.$store.dispatch(
            "discover/fetchDiscoverArticleComments",
            this.storyId
          );
          this.loader = false;
          this.showCommentDeleteModal = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async deleteDiscussionCommentReply(replyCommentId){
      this.loader = true;
      try {
        const payload = {
          discussion_id: replyCommentId,
          interest_id: this.storyId,
          interest_group_id: this.interestGroupId,
        };
        const response = await this.$store.dispatch(
          "discover/deleteDiscussionComments",
          payload
        );
        if (response.status === 204) {
          const payload = {
          interest_group_id: this.interestGroupId,
          interest_id: this.storyId
        }
          await this.$store.dispatch(
            this.isPrivateTopic ? "discussion/setPrivateDiscussionComments" : "discussion/setPublicDiscussionComments",
            payload
          );
          this.showCommentDeleteModal = false;
        }
        this.loader = false;
      } catch (err) {
        console.log(err);
      }
    },
    async deleteSavedFolderCommentReply(replyCommentId) {
      this.loader = true;
      try {
        const payload = {
          comment_id: replyCommentId,
          folder_id: this.storyId,
        };
        const response = await this.$store.dispatch(
          "profile/deleteSavedFolderComment",
          payload
        );
        if (response.status === 204) {
          await this.$store.dispatch(
            "profile/fetchSavedFolderComments",
            this.storyId
          );
          this.loader = false,
          this.showCommentDeleteModal = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async deleteFolderCommentReply(replyCommentId) {
      this.loader = true;
      try {
        const payload = {
          commentId: replyCommentId,
          folderId: this.storyId,
        };
        const response = await this.$store.dispatch(
          "folders/deleteFolderComments",
          payload
        );
        if (response.status === 204) {
          const parentIndex = this.getAllFolderDiscussions.findIndex((val) => val.id === this.parentCommentId);
          const childIndex = this.getAllFolderDiscussions[parentIndex].child.findIndex(child => child.id === replyCommentId);
          this.getAllFolderDiscussions[parentIndex].child.splice(childIndex, 1);
          this.loader = false,
          this.showCommentDeleteModal = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reply-comment {
  &__comment-details {
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    margin-left: 3.5rem;
    // margin-bottom: 2rem;
    .user-image {
      width: 3rem;
      height: auto;
      border-radius: 100%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: rgb(238 237 237 / 63%);
        object-fit: contain;
      }
    }
    .user-details {
      background-color: #ffffff57;
      padding: 1rem;
      margin-left: 0.6rem;
      border-radius: 0.5rem;
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
          font-size: 1.6rem;
          font-family: $font-primary-bold;
          margin-right: 2rem;
          margin-bottom: 0;
          span {
            font-size: 1.3rem;
            font-family: $font-primary;
            opacity: 0.6;
            margin-left: 0.5rem;
          }
        }
        .dropdown {
          .icon {
            color: $color-black;
            font-size: 1.5rem;
          }
        }
      }
      &__body {
        p {
          font-size: 1.6rem;
          font-family: $font-primary;
          line-height: 2rem;
          margin-bottom: 0;
          white-space: break-spaces;
          a{
            color: $color-faded-blue;
          }
        }
        .pdf-file-container  {
          overflow-x: auto;
          width: 100%;
          display: flex;
          gap: 1rem;
          padding: 0.5rem 0;
          &::-webkit-scrollbar {
            height: 0.3rem;
          }
          &::-webkit-scrollbar-track {
            background-color: $color-white;
            border-radius: 0.8rem;
            height: 0.3rem;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 0.8rem;
          }
          @include respond(phone-x-small) {
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }
  }
  .edit-story-reply-comments {
    margin-left: 3.5rem;
  }

  &__comment-btns {
    margin-left: 7.5rem;
    margin-top: 0.1rem;
    margin-bottom: 1rem;
    button {
      border: none;
      background-color: transparent;
      color: $color-black;
      font-size: 1.2rem;
      font-family: $font-primary-bold;
      opacity: 0.6;
      cursor: pointer;
      &:first-child {
        margin-right: 1rem;
      }
    }
  }
  &__comment-input {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 1rem 0;
    margin-left: 7rem;
    .user-image {
      width: 3rem;
      height: auto;
      border-radius: 100%;
      margin-right: 0.6rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
  }
}
</style>