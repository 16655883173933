<template>
  <div class="comment-container">
    <div class="comment">
      <edit-comments
        v-if="!visibleComment"
        :profilePicture="profilePicture"
        :comment="comment"
        @close-comment-box="cancelComment"
        :commentId="parentId"
        :storyId="storyId"
        :commentFor="commentFor"
        :isPrivateTopic="isPrivateTopic"
        :interestGroupId="interestGroupId"
      ></edit-comments>
      <div v-if="visibleComment">
        <div class="comment__comment-details">
          <div class="user-image">
            <img :src="profilePicture" />
          </div>

          <div class="user-details">
            <div class="user-details__header">
              <h4>
                {{ fullName }}<span>{{ time }}</span>
              </h4>
              <a-dropdown
                v-if="isLoggedInUser == commentBy"
                :trigger="['click']"
                class="dropdown"
              >
                <a data-cy="edit_deleteStoryComments" class="ant-dropdown-link" @click.prevent>
                  <i class="icon icon-vertical_three-dots"></i>
                </a>
                <template #overlay>
                  <a-menu class="comment-dropdown">
                    <a-menu-item key="0">
                      <a @click="editComment()">Edit</a>
                    </a-menu-item>
                    <a-menu-item key="1">
                      <a @click="visibleCommentDeleteModal()">Delete</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
            <div class="user-details__body">
              <p v-if="comment.includes('youtube.com') || comment.includes('vimeo.com')">
                <a :href="comment" target="_black">{{ comment }}</a>
              </p>
              <p v-else>
                {{ comment }}
              </p>
              <div v-if="file" class="pdf-file-container">
                <PdfCard
                  :pdfFileName="fileName"
                  :pdfUrl="file"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="comment__comment-btns">
          <!-- <button>Like</button> -->
          <button @click="handleReplyField()">Reply</button>
        </div>
      </div>
      <div v-if="commentsReplies !== 0">
        <div v-if="commentFor == 'impact'">
          <comments-reply
            v-for="(reply, index) in commentsReplies"
            :key="index"
            :profilePicture="reply.profile_picture"
            :fullName="reply.full_name"
            :comment="reply.comment"
            :commentsNestedReplies="reply.child"
            :storyId="reply.impact_story"
            :replyId="reply.id"
            :displayPicture="displayPicture"
            :time="reply.human_time"
            :userId="reply.user_id"
            :commentFor="commentFor"
          ></comments-reply>
        </div>
        <div v-if="commentFor == 'discover'">
          <comments-reply
            v-for="(reply, index) in commentsReplies"
            :key="index"
            :profilePicture="reply.user.profile_picture"
            :fullName="reply.user.full_name"
            :comment="reply.comment"
            :commentsNestedReplies="reply.child"
            :storyId="reply.article_data"
            :replyId="reply.id"
            :displayPicture="displayPicture"
            :time="reply.human_time"
            :userId="reply.user.id"
            :commentFor="commentFor"
          ></comments-reply>
        </div>
        <div v-if="commentFor == 'topics'">
          <comments-reply
            v-for="(reply, index) in commentsReplies"
            :key="index"
            :profilePicture="reply.user.profile_picture"
            :fullName="reply.user.full_name"
            :comment="reply.comment"
            :commentsNestedReplies="reply.child"
            :storyId="reply.interest"
            :replyId="reply.id"
            :displayPicture="displayPicture"
            :time="reply.human_time"
            :userId="reply.user.id"
            :commentFor="commentFor"
            :interestGroupId="interestGroupId"
            :isPrivateTopic="isPrivateTopic"
          ></comments-reply>
        </div>
        <div v-if="commentFor == 'saved-folder'">
          <comments-reply
            v-for="(reply, index) in commentsReplies"
            :key="index"
            :profilePicture="reply.profile_picture"
            :fullName="reply.full_name"
            :comment="reply.comment"
            :commentsNestedReplies="reply.child"
            :storyId="reply.folder"
            :replyId="reply.id"
            :displayPicture="displayPicture"
            :time="reply.human_time"
            :userId="reply.created_by"
            :commentFor="commentFor"
          ></comments-reply>
        </div>
        <div v-if="commentFor == 'folders'">
          <comments-reply
            v-for="(reply, index) in commentsReplies"
            :key="index"
            :profilePicture="reply.user.profile_picture"
            :fullName="reply.user.full_name"
            :comment="reply.comment"
            :file="reply.file"
            :fileName="reply.file_name"
            :commentsNestedReplies="reply.child"
            :storyId="reply.folder"
            :replyId="reply.id"
            :parentCommentId="reply.parent"
            :displayPicture="displayPicture"
            :time="reply.human_time"
            :userId="reply.user.id"
            :commentFor="commentFor"
          ></comments-reply>
        </div>
      </div>
      <div v-if="showReplyField" class="comment-input comment__reply-comment">
        <div class="user-image">
          <img :src="displayPicture" />
        </div>
        <div class="comment__reply-comment--right">
          <div class="send-comment">
            <div class="send-comment__left">
              <textarea
                id=""
                rows="2"
                placeholder="Write a comment..."
                ref="replyComment"
                v-model="addReply"
              ></textarea>
              <!-- <div class="send-comment__left--buttons">
                <button class="btn">
                  <img src="@/assets/icons/attachment-icon.svg" />
                  <label for="file-upload-child"></label>
                  <input 
                    type="file" 
                    id="file-upload-child" 
                    name="file"
                    @change="handleFileUpload($event)"
                  > 
                </button>
              </div> -->
            </div>
            <the-loader v-if="loading" class="comment-textarea-loader"></the-loader>
            <button v-if="commentFor == 'impact'" class="send-btn">
              <i
                class="icon icon-send"
                @click="createImpactReplyComment(storyId, parentId)"
              ></i>
            </button>
            <button v-if="commentFor == 'discover'" class="send-btn">
              <i
                class="icon icon-send"
                @click="createDiscoverReplyComment(storyId, parentId)"
              ></i>
            </button>
            <button v-if="commentFor == 'topics'" class="send-btn">
              <i
                class="icon icon-send"
                @click="createDiscussionReplyComment(storyId, parentId)"
              ></i>
            </button>
            <button v-if="commentFor == 'saved-folder'" class="send-btn">
              <i
                class="icon icon-send"
                @click="createSavedFolderReplyComment(storyId, parentId)"
              ></i>
            </button>
            <button v-if="commentFor == 'folders'" class="send-btn">
              <i
                class="icon icon-send"
                @click="createFolderReplyComment(storyId, parentId)"
                :class="{ 'disbale': loading }"
              ></i>
            </button>
          </div>
          <div class="attach-file-main-container">
            <div class="attach-file-container">
              <div v-for="file in attachedFiles" :key="file" class="attach-file-container__attach-file">
                <img src="@/assets/icons/pdf-icon.png"
                    alt="PDF Icon" />
                <p>file {{ file.name }}</p>
                <button @click="removeFile(index)" class="cancel-btn">
                  <i class="icon icon-add_plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <delete-modal
    v-if="commentFor == 'impact'"
    :visible="showCommentDeleteModal"
    @handle-close="showCommentDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteImpactComment()"
  ></delete-modal>
  <delete-modal
    v-if="commentFor == 'discover'"
    :visible="showCommentDeleteModal"
    @handle-close="showCommentDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteDiscoverComment()"
  ></delete-modal>
  <delete-modal
    v-if="commentFor == 'topics'"
    :visible="showCommentDeleteModal"
    @handle-close="showCommentDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteDiscussionComment()"
  ></delete-modal>
  <delete-modal
    v-if="commentFor == 'saved-folder'"
    :visible="showCommentDeleteModal"
    @handle-close="showCommentDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteSavedFolderComment()"
  ></delete-modal>
  <delete-modal
    v-if="commentFor == 'folders'"
    :visible="showCommentDeleteModal"
    @handle-close="showCommentDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteFolderComment()"
  ></delete-modal>
</template>

<script>
import EditComments from "./EditComment.vue";
import CommentsReply from "./CommentsReply.vue";
import DeleteModal from "../DeleteModal.vue";
import TheLoader from "../TheLoader.vue";
import PdfCard from "../../GlocalFolders/CommentPDFCard.vue";

export default {
  components: {
    EditComments,
    CommentsReply,
    DeleteModal,
    TheLoader,
    PdfCard
  },
  props: {
    profilePicture: String,
    fullName: String,
    commentsReplies: Array,
    comment: String,
    file: String,
    fileName: String,
    storyId: String,
    parentId: String,
    displayPicture: String,
    time: String,
    commentBy: Number,
    commentFor: String,
    isPrivateTopic: Boolean,
    interestGroupId: String
  },
  emits: ["edit-comment"],
  data() {
    return {
      showReplyField: false,
      addReply: "",
      visibleComment: true,
      showCommentDeleteModal: false,
      loader: false,
      loading: false,
      attachedFiles: []
    };
  },
  computed: {
    isLoggedInUser() {
      return this.$store.getters["profile/currentLoginUser"];
    },
    getSearchedTopic(){
      return this.$store.getters["article/getSearchQuery"];
    },
    comments() {
      return this.$store.getters["profile/getSavedFoldersComments"];
    },
    getAllFolderDiscussions() {
      return this.$store.getters["folders/getFolderComments"].results;
    },
  },
  methods: {
    handleReplyField() {
      this.showReplyField = !this.showReplyField;
    },
    async createImpactReplyComment(storyID, commentID) {
      try {
        this.loading = true;
        const payload = {
          comment: this.addReply,
          parent: commentID,
          story_id: storyID,
        };
        const response = await this.$store.dispatch(
          "impact/impactArticleComment",
          payload
        );
        if (response.status === 201) {
          this.addReply = "";
          await this.$store.dispatch(
            "impact/fetchImpactArticleComments",
            storyID
          );
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    editComment() {
      this.visibleComment = false;
    },
    cancelComment() {
      this.visibleComment = true;
    },
    visibleCommentDeleteModal() {
      this.showCommentDeleteModal = true;
    },
    async deleteImpactComment() {
      this.loader = true;
      try {
        const payload = {
          comment_id: this.parentId,
          story_id: this.storyId,
        };
        const response = await this.$store.dispatch(
          "impact/deleteImpactArticleComment",
          payload
        );
        if (response.status === 204) {
          await this.$store.dispatch(
            "impact/fetchImpactArticleComments",
            this.storyId
          );
          this.loader = false,
          this.showCommentDeleteModal = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async createDiscoverReplyComment(storyID, commentID) {
      try {
        this.loading = true;
        const payload = {
          comment: this.addReply,
          parent: commentID,
          article_id: storyID,
        };
        const response = await this.$store.dispatch(
          "discover/discoverArticleComment",
          payload
        );
        if (response.status === 201) {
          this.addReply = "";
          await this.$store.dispatch(
            "discover/fetchDiscoverArticleComments",
            this.storyId
          );
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async deleteDiscoverComment() {
      this.loader = true;
      try {
        const payload = {
          comment_id: this.parentId,
          article_id: this.storyId,
        };
        const response = await this.$store.dispatch(
          "discover/deleteDiscoverArticleComment",
          payload
        );
        if (response.status === 204) {
          await this.$store.dispatch(
            "discover/fetchDiscoverArticleComments",
            this.storyId
          );
          this.loader = false,
          this.showCommentDeleteModal = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async createDiscussionReplyComment(topicId, commentID) {
      try {
        this.loading = true;
        const payload = {
          comment: this.addReply,
          parent: commentID,
          interest_id: topicId,
          interest_group_id: this.interestGroupId,
        };
        const response = await this.$store.dispatch(
          "discussion/createDiscussionComment",
          payload
        );
        if (response.status === 201) {
          this.addReply = "";
          const payload = {
          interest_group_id: this.interestGroupId,
          interest_id: topicId
        }
          await this.$store.dispatch(
            this.isPrivateTopic? "discussion/setPrivateDiscussionComments" : "discussion/setPublicDiscussionComments",
            payload
          );
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async deleteDiscussionComment(){
      this.loader = true;
      try {
        const payload = {
          discussion_id: this.parentId,
          interest_id: this.storyId,
          interest_group_id: this.interestGroupId,
        };
        const response = await this.$store.dispatch(
          "discover/deleteDiscussionComments",
          payload
        );
        if (response.status === 204) {
          const payload = {
          interest_group_id: this.interestGroupId,
          interest_id: this.storyId,
        }
          await this.$store.dispatch(
            this.isPrivateTopic? "discussion/setPrivateDiscussionComments" : "discussion/setPublicDiscussionComments",
            payload
          );
          this.loader = false,
          this.showCommentDeleteModal = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async createSavedFolderReplyComment(storyID, commentID) {
      try {
        this.loading = true;
        const payload = {
          comment: this.addReply,
          parent: commentID,
          folder_id: storyID,
        };
        const response = await this.$store.dispatch(
          "profile/createSaveFolderComment",
          payload
        );
        if (response.status === 201) {
          this.addReply = "";
          await this.$store.dispatch(
            "profile/fetchSavedFolderComments",
            this.storyId
          );
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async deleteSavedFolderComment() {
      this.loader = true;
      try {
        const payload = {
          comment_id: this.parentId,
          folder_id: this.storyId,
        };
        const response = await this.$store.dispatch(
          "profile/deleteSavedFolderComment",
          payload
        );
        if (response.status === 204) {
          const updatedCommentList = this.comments.filter((val) => {
            return val.id !== this.parentId;
          });
          this.$store.commit("profile/setSavedFolderComments", updatedCommentList);
          this.loader = false,
          this.showCommentDeleteModal = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    handleFileUpload(event) {
      const files = event.target.files; 
      for (let i = 0; i < files.length; i++) {
        this.attachedFiles.push(files[i]);
      }
    },
    removeFile(index) {
      this.attachedFiles.splice(index, 1);
    },
    async createFolderReplyComment(storyID, commentID) {
      try {
        this.loading = true;
        const form = new FormData();
        form.append("comment", this.addReply);
        form.append("parent", commentID);
        this.attachedFiles.forEach((val) => {
          form.append("file", val);
          form.append("file_name", val.name);
        });
        const payload = {
          comment: form,
          folderId: storyID,
        };
        const response = await this.$store.dispatch(
          "folders/createFolderComment",
          payload
        );
        if (response.status === 201) {
          this.addReply = "";
          this.attachedFiles = [];
          const index = this.getAllFolderDiscussions.findIndex((findItem) => findItem.id === commentID);
          this.getAllFolderDiscussions[index].child.push(response.data)
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async deleteFolderComment() {
      this.loader = true;
      try {
        const payload = {
          commentId: this.parentId,
          folderId: this.storyId,
        };
        const response = await this.$store.dispatch(
          "folders/deleteFolderComments",
          payload
        );
        if (response.status === 204) {
          const updatedCommentList = this.getAllFolderDiscussions.findIndex((val) => val.id === this.parentId);
            if (updatedCommentList !== -1) {
              this.getAllFolderDiscussions.splice(updatedCommentList, 1);
            }
          this.loader = false,
          this.showCommentDeleteModal = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
.comment-container {
  .comment {
    &__comment-details {
      display: flex;
      justify-items: flex-start;
      align-items: flex-start;
      .user-image {
        width: 3rem;
        height: auto;
        border-radius: 100%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          background-color: rgb(238 237 237 / 63%);
          object-fit: contain;
        }
      }
      .user-details {
        background-color: rgba(238, 237, 237, 0.63);
        padding: 1rem;
        margin-left: 0.6rem;
        border-radius: 0.5rem;
        max-width: 100%;
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          h4 {
            font-size: 1.6rem;
            font-family: $font-primary-bold;
            margin-right: 2rem;
            margin-bottom: 0;
            span {
              font-size: 1.3rem;
              font-family: $font-primary;
              opacity: 0.6;
              margin-left: 0.5rem;
            }
          }
          .dropdown {
            .icon {
              color: $color-black;
              font-size: 1.5rem;
            }
          }
        }
        &__body {
          p {
            font-size: 1.6rem;
            font-family: $font-primary;
            line-height: 2rem;
            margin-bottom: 0;
            white-space: break-spaces;
            a{
              color: $color-faded-blue;
            }
          }
          .pdf-file-container {
            overflow-x: auto;
            width: 100%;
            display: flex;
            gap: 1rem;
            padding: 0.5rem 0;
            &::-webkit-scrollbar {
              height: 0.3rem;
            }
            &::-webkit-scrollbar-track {
              background-color: $color-white;
              border-radius: 0.8rem;
              height: 0.3rem;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $color-primary;
              border-radius: 0.8rem;
            }
            @include respond(phone-x-small) {
              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
        }
      }
    }
    .edit-story-reply-comments {
      margin-left: 3.5rem;
    }

    &__comment-btns {
      margin-left: 4rem;
      margin-top: 0.1rem;
      margin-bottom: 1rem;
      button {
        border: none;
        background-color: transparent;
        color: $color-black;
        font-size: 1.2rem;
        font-family: $font-primary-bold;
        opacity: 0.6;
        cursor: pointer;
        &:first-child {
          margin-right: 1rem;
        }
      }
    }
    &__reply-comment {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0rem 0 2rem 3.5rem !important;
      .user-image {
        width: 3rem;
        height: auto;
        border-radius: 100%;
        margin-right: 0.6rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          background-color: rgb(238 237 237 / 63%);
          object-fit: contain;
        }
      }
      &--right {
        width: 100%;
        .send-comment {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          background-color: rgb(238 237 237 / 63%);
          padding: 1rem 1rem;
          border-radius: 0.5rem;
          position: relative;
          &__left {
            width: 100%;
            textarea {
              width: 100%;
              background-color: transparent;
              border: none;
              outline: none;
              font-size: 1.6rem;
              font-family: $font-primary;
              line-height: 1.8rem;
              resize: none;
              &::placeholder {
                opacity: 0.7;
              }
            }
            &--buttons {
              display: flex;
              align-items: center;
              width: fit-content;
              margin-left: auto;
              .btn {
                width: 1.5rem;
                height: 1.5rem;
                background-color: transparent;
                border: none;
                outline: none;
                border-radius: 0 !important;
                transition: all .1s ease-in-out;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: transparent;
                position: relative;
                line-height: normal;
                margin-bottom: 0.5rem;
                img {
                  width: 1.5rem;
                }
                input[type='file'] {
                  display: none
                }
                label {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                }
              }
            }
          }
          .send-btn {
            border: none;
            height: fit-content;
            width: fit-content;
            line-height: normal;
            margin-left: 1rem;
            cursor: pointer;
            background-color: transparent;
            .icon {
              color: $color-primary;
              font-size: 2rem;
            }
            .disbale {
              opacity: 0.5;
              cursor: default;
            }
          }
        }
        .attach-file-main-container {
          width: 100%;
          .attach-file-container {
            width: 100%;
            padding: 1rem 0;
            overflow-x: auto;
            display: flex;
            align-items: center;
            &__attach-file {
              display: flex;
              border: 1px solid rgb(224, 224, 224);
              border-radius: 0.5rem;
              height: 5rem;
              padding: 0 0.5rem;
              display: flex;
              align-items: center;
              position: relative;
              width: 30rem;
              min-width: 30rem;
              margin-right: 2rem;
              img {
                width: 3.5rem;
              }
              p {
                margin-left: 0.5rem;
                font-size: 1.6rem;
                font-family: $font-primary;
                margin-bottom: 0;
                max-width: 12rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              .cancel-btn {
                background-color: rgb(199, 199, 199);
                border: none;
                outline: none;
                position: absolute;
                right: -0.8rem;
                top: -0.8rem;
                border-radius: 100% !important;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1.5rem;
                height: 1.5rem;
                cursor: pointer;
                .icon {
                  transform: rotate(225deg);
                  display: block;
                  font-size: 0.8rem;
                }
              }
            }
            &::-webkit-scrollbar {
              width: 0.3rem;
            }

            &::-webkit-scrollbar-track {
              background-color: transparent;
              border-radius: 0.8rem;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $color-primary;
              border-radius: 0.8rem;
              height: 0.3rem;
            }
          }
        }
      }
      
    }
  }
}
.ant-dropdown {
  z-index: 44444;
  .comment-dropdown {
    padding: 0;
    .ant-dropdown-menu-item {
      padding: 0;
      .ant-dropdown-menu-title-content {
        padding: 0.5rem 2rem;
        a {
          font-size: 1.3rem;
          font-family: $font-secondary;
        }
      }
    }
  }
}
</style>
