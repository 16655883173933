<template>
  <div @click="showDrawer" class="impact-article-card">
    <div
      class="impact-article-card__header"
      :style="{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 41.46%), url(${image})`,
      }"
    >
      <h2 class="impact-article-card__header--title">
        {{ title }}
      </h2>
      <div class="impact-article-card__header--auth">
        <div class="details">
          <img :src="authorProfilePic" alt="icon" class="user-image" />
          <div class="name-and-date">
            <h2>
              {{ authorName }}
            </h2>
            <p>Publish date: {{ publishedDate }}</p>
          </div>
        </div>
        <div v-if="visibleBtnMenu" @click.stop class="dropdown-container">
          <a-dropdown :trigger="['click']">
            <a class="ant-dropdown-link" @click.prevent>
              <i class="icon icon-vertical_three-dots"></i>
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item v-if="authorId === isLoggedinUserId" key="0" @click="visibleStoryEditModal()">
                  <a>Edit</a>
                </a-menu-item>
                <a-menu-item v-if="authorId === isLoggedinUserId" key="1" @click="visibleStoryDeleteModal()">
                  <a>Delete</a>
                </a-menu-item>
                <a-menu-item v-if="authorId != isLoggedinUserId" key="1" @click="visibleReportModal()">
                  <a>Report</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div>
    <div class="impact-article-card__body">
      <div class="body">
        <p class="description">
          {{ description }}
        </p>
      </div>
      <div @click.stop class="footer">
        <the-reactions
        :isImpact="true"
        :articleId="id"
        :userReaction="userReaction"
        ></the-reactions>
        <button @click="visibleShareArticleModal()" class="share-btn">
          <img src="@/assets/icons/reply.svg" />Share
        </button>
      </div>
    </div>
  </div>
  <delete-modal
    :visible="showDeleteModal"
    @handle-close="showDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteStory()"
  ></delete-modal>
  
  <report-modal
  :visible="showReportModal"
  @handle-close="showReportModal = false"
  title="Why are you reporting this highlighted current event?"
  reportDescription="Please select a problem"
  :storyId="id"
  >
  </report-modal>

  <edit-story-modal
  ref="story-modal"
  :action="action"
  :showModal="showEditStoryModal"
  @handle-close="showEditStoryModal = false"
  :storyId="id"
  :currentPage="currentPage"
  ></edit-story-modal>
  <message-toaster 
  :visible="showMessageModal"
  :message="message"
  @handle-close="showMessageModal = false"
  >
  </message-toaster>
  <a-modal
    v-model:visible="showArticleShareModal"
    :footer="null"
    wrapClassName="share-article-modal"
  >
    <article-share-modal
      :title="title"
      :image="image"
      :source="authorName"
      :url="url"
      :date="publishedDate"
      :id="id"
      :isImpact="true"
      @close-article-share-modal="closeShareArticleModal"
    ></article-share-modal>
  </a-modal>
</template>

<script>
import TheReactions from "../BaseComponents/TheReactions.vue";
import DeleteModal from ".././BaseComponents/DeleteModal.vue";
import ReportModal from ".././BaseComponents/ReportModal.vue"
import EditStoryModal from "./WriteStoryModal.vue";
import MessageToaster from '../BaseComponents/MessageToaster.vue';
import { mapActions, mapGetters } from "vuex";
import ArticleShareModal from "../BaseComponents/ArticleShareModal.vue";

export default {
  components: {
    TheReactions,
    MessageToaster,
    DeleteModal,
    ReportModal,
    EditStoryModal,
    ArticleShareModal
  },
  props: {
    id: String,
    title: String,
    description: String,
    publishedDate: String,
    authorProfilePic: String,
    authorName: String,
    media: Array,
    currentPage: Number,
    isPortFolio: Boolean,
    visibleBtnMenu: Boolean,
    emails: Array,
    collabrators: Array,
    authorId: Number,
    userReaction: Object,
    totalArticleReactions: {
      type: Object,
      default: () => ({}),
    },
    userReactionRepresentation: String,
  },
  data() {
    return {
      showDeleteModal: false,
      loader: false,
      showReportModal: false,
      action: "",
      showEditStoryModal: false,
      disableBtn: false,
      showMessageModal: false,
      message: {},
      showArticleShareModal: false,
      url: ""
    };
  },
  computed: {
    image(){
      if(this.media.length > 0){
        return this.media[0].media;
      }
      return "";
    },
    isLoggedInUser(){
      console.log(this.$store.getters["profile/isLoggedinUser"])
      return this.$store.getters["profile/isLoggedinUser"];
    },
    isLoggedinUserId() {
      return parseInt(this.$store.getters["profile/currentLoginUser"]);
    },
  },
  emits: ["visible-drawer"],
  methods: {
    ...mapActions('impact',['updateArticle']),
    async showDrawer() {
      this.$emit("visible-drawer", { articleId: this.id });
    },
    async impactArticles() {
      this.disableBtn = true;
      try {
        const payload = {
          query: "",
          offset: (this.currentPage - 1) * 9,
          limit: this.getArticles,
          author: this.$route.params.id ? this.$route.params.id : "",
        };
        const response = await this.$store.dispatch("impact/fetchImpactArticle", payload);
        if(response.status == 200){
        this.disableBtn = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    
    visibleStoryDeleteModal() {
      this.showDeleteModal = true;
    },
    async deleteStory() {
      this.loader = true;
      try {
        const payload = {
          story_id: this.id,
        };
        const response = await this.$store.dispatch(
          "impact/deleteImpactArticle",
          payload
        );
        if (response.status === 204) {
          this.impactArticles();
          this.showMessageModal = true;
          this.message = {
            title: "Current Event Deleted Successfully",
            type: "success",
          }
          this.loader = false,
          this.showDeleteModal = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    visibleStoryEditModal(){
      this.action = 'edit';
      this.showEditStoryModal = true;
      this.$refs["story-modal"].selectStory(this.title,this.description,this.media,this.emails,this.collabrators);
    },
    visibleReportModal() {
      this.showReportModal = true;
    },
    updateImpactArticleArray(articleId,article) { 
      let articleIndex = this.getImpactArticles.results.findIndex(article => article.id === articleId)
      const articleDetails = {
        articleIndex,
        article
      }
      this.updateArticle(articleDetails)
      this.disableBtn = false;
    },
    visibleShareArticleModal() {
      this.showArticleShareModal = true;
    },
    closeShareArticleModal(val, messageModal, type) {
      this.showArticleShareModal = val;
      if (type == "success") {
        this.message = {
          title: "Article shared successfully",
          type: "success",
        };
      } else {
        this.message = {
          title: type,
          type: "failure",
        };
      }
      this.showMessageModal = messageModal;
    },
  },
};
</script>

<style lang="scss">
.impact-article-card {
  max-width: 37rem;
  margin: 0 auto;
  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    height: 100%;
    max-height: 26rem;
    min-height: 26rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: inherit;
    background-position-y: inherit;
    background-color: $color-black;
    &--title {
      font-size: 1.8rem;
      font-family: $font-primary-medium;
      font-weight: 700;
      line-height: 2.4rem;
      color: $color-white;
      font-weight: 700;
      margin-bottom: 0;
      padding: 0 1.5rem;
      max-width: 70%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &--auth {
      display: flex;
      align-items: center;
      padding: 1.8rem 1.5rem 3rem;
      width: 100%;
      .details {
        display: flex;
        align-items: center;
        width: 100%;
        .user-image {
          width: 3.7rem;
          height: auto;
          border-radius: 100%;
          margin-right: 1rem;
          @include respond(laptop-large) {
            width: 3rem;
          }
        }
        .name-and-date {
          h2 {
            font-size: 1.2rem;
            font-family: $font-primary-medium;
            font-weight: 700;
            line-height: 1.6rem;
            color: $color-white;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
            margin-bottom: 0.5rem;
          }
          p {
            font-size: 1.2rem;
            font-family: $font-primary-medium;
            line-height: 1.8rem;
            color: rgba(180, 180, 180, 1);
            margin-bottom: 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
      .dropdown-container {
        .ant-dropdown-link {
          .icon {
            color: $color-white;
            font-size: 3rem;
            line-height: normal;
          }
        }
      }
    }
  }
  &__body {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: $color-white;
    border-top: none;
    border-end-end-radius: 1rem;
    border-end-start-radius: 1rem;
    .body {
      padding: 1.9rem 1.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      margin-bottom: 1.9rem;
      .description {
        font-size: 1.4rem;
        font-family: $font-primary;
        margin-bottom: 0;
        line-height: 2rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    .footer {
      display: flex;
      align-items: center;
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      .the-horizontal-reactions-bar {
        width: 50%;
      }
      .share-btn {
        width: 50%;
        border: none;
        font-size: 1.3rem;
        font-family: $font-primary-medium;
        height: fit-content;
        background-color: transparent;
        color: $color-black;
        border-radius: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 1.6rem 0.3rem;
        border-left: 1px solid rgba(0, 0, 0, 0.2);
        @include respond(phone-x-small) {
          font-size: 1.8rem;
        }
        img {
          width: 1.6rem;
          margin-right: 1.3rem;
        }
      }
    }
  }
}
</style>
